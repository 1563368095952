<template>
    <header class="com-card-header">
        <div class="left">
            <i :class="$attrs.icon"></i>
            <span class="title">
                {{ $attrs.title }}
                <!-- <i class="iconfont icon-shuoming"></i> -->
            </span>
            <span v-if="$attrs.date" class="date">
                {{ $attrs.date }}
            </span>
            <el-select
                v-if="$attrs.options"
                v-model="value"
                :style="{'width':width}"
                placeholder="请选择"
                size="small"
                @change="handleFilter"
            >
                <el-option
                    v-for="item in $attrs.options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
            </el-select>
            <slot v-else name="options">
            </slot>
            <!-- <el-dropdown v-if="$attrs.filter">
        <span class="el-dropdown-link">
          {{ value }}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown" @click="handleClick">
          <el-dropdown-item  v-for="item in options" :key="item.value">{{ item.label }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown> -->
        </div>
        <div class="right">
            <i v-if="$attrs.link" class="iconfont icon-tiaozhuan" @click="handleClick"></i>
            <slot v-else name="header"></slot>
        </div>
    </header>
</template>

<script>

export default {
    name: '',
    inheritAttrs: false,
    props: {
        width: {
            type: String,
            default: '1.4rem'
        }
    },
    data () {
        return {
            value: 1
        }
    },
    methods: {
        handleFilter (val) {
            console.log(val)
            // this.value = item.label;
            this.$emit('handleFilter', val)
        },
        handleClick () {
            if (this.$attrs.link) {
                this.$router.push(this.$attrs.link)
            }
        }
    }
}
</script>
<style lang='less' scoped>
header {
    display: flex;
    align-items: center;
    min-height: 0.3rem;
    max-height: 0.3rem;

    .left {
        display: flex;
        flex: 1;
        align-items: center;
        vertical-align: bottom;

        > i {
            margin-right: 0.1rem;
            font-size: 0.24rem;
            color: orange;
        }

        .title {
            display: flex;
            align-items: center;
            font-size: 0.24rem;
            font-weight: 500;
            line-height: 0.24rem;
            color: #606266;

            i {
                margin: 0 0.04rem;
                margin-top: 0.07rem;
                font-size: 0.14rem;
                color: #c0c4cc;
            }
        }

        .date {
            padding-top: 0.11rem;
            margin-left: 0.12rem;
            // line-height: .24rem;
            font-size: 0.14rem;
            color: #909399;
        }

        /deep/ .el-input__inner {
            background: rgba(255, 255, 255, 0);
            border: none;
            // width: 1.4rem;
        }
    }

    .right {
        display: flex;
        align-items: center;

        i {
            font-size: 0.28rem;
            color: #a7b6c3;
            cursor: pointer;
        }

        i:hover {
            color: #d0d6da;
        }

        i + i,
        span + i {
            margin-left: 0.12rem;
        }
    }
}
</style>
