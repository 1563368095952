<template>
    <Card class="default-widget" :header="true">
        <template v-slot:cardHeader>
            <i v-if="!editing" class="iconfont icon-shezhi2" @click="handleOption"></i>
            <el-button
                v-if="editing"
                class="text-button"
                type="text"
                @click="handleReset"
            >
                恢复默认
            </el-button>
            <el-button
                v-if="editing"
                class="text-button"
                type="text"
                @click="handleOption"
            >
                退出编辑
            </el-button>
        </template>
        <template>
            <div class="info">
                <div class="logo" :style="`background-image: url(${logo});`"></div>
                <div class="name">{{ currentUser.displayName }}</div>
                <div class="notices">
                    <div v-for="({title, count}, index) in notices" :key="index" class="item">
                        <span>
                            {{ count }}
                        </span>
                        <span>
                            {{ title }}
                        </span>
                    </div>
                </div>
            </div>
        </template>
    </Card>
</template>
<script>
import todoSvc from 'services/todo'
import Card from './components/card'

export default {
    name: 'DefaultWidget',
    components: {
        Card
    },
    data: function () {
        return {
            editing: false,
            notice: [
                {
                    title: '待办',
                    count: 0
                },
                {
                    title: '通知',
                    count: 0
                },
                {
                    title: '建议',
                    count: 0
                }
            ],
            notices: {
                todo: {
                    title: '待办',
                    count: 0
                },
                notice: {
                    title: '通知',
                    count: 0
                },
                advice: {
                    title: '建议',
                    count: 0
                }
            }
        }
    },
    computed: {
        currentUser () {
            return this.$store.getters.currentUser
        },
        logo () {
            return this.currentUser.avatarUrl || require('@/assets/images/avart.png')
        }
    },
    mounted () {
        this.getUntreatedTotal()
    },
    methods: {
        handleEdit (val) {
            this.editing = !this.editing
            this.$emit('handle-edit', this.editing)
        },
        handleOption () {
            this.handleEdit()
        },
        handleReset () {
            this.$confirm('确定恢复默认的工作台吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.handleEdit()
            })
        },
        async getUntreatedTotal () {
            const { data = {} } = await todoSvc.getUntreatedTotal()
            const { untreated } = data
            this.notices.todo.count = untreated
        }
    }
}
</script>
<style lang="scss" scoped>
$defaultWidth: 100%;

.default-widget {
    .info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .logo {
        width: 1.2rem;
        height: 1.2rem;
        background-color: #e6f0ff;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        border-radius: 50%;
    }

    .name {
        margin-top: 0.07rem;
        margin-bottom: 0.35rem;
        font-size: 0.18rem;
        font-weight: 500;
        line-height: 0.24rem;
        color: #606266;
    }

    .notices {
        display: flex;
        justify-content: space-between;
        min-width: calc(100% - 0.94rem);

        & > div {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-items: center;

            span {
                font-size: 0.36rem;
                font-weight: 500;
                line-height: 0.43rem;
                color: #54636f;

                &:last-of-type {
                    font-size: 0.14rem;
                    font-weight: 500;
                    line-height: 0.24rem;
                    color: #a7b6c3;
                }
            }
        }
    }

    .text-button {
        padding: 0;
        font-size: 0.14rem;
        font-weight: 400;
    }
}
</style>
