<template>
    <div class="card-component">
        <div class="main">
            <Header v-if="$attrs.title || $attrs.header" v-bind="$attrs" v-on="$listeners">
                <template v-slot:options>
                    <slot name="cardOptions"></slot>
                </template>
                <template v-slot:header>
                    <slot name="cardHeader"></slot>
                </template>
            </Header>
            <div class="content">
                <slot>
                </slot>
            </div>
        </div>
    </div>
</template>

<script>
import Header from './header'
export default {
    name: 'CardComponent',
    components: { Header },
    inheritAttrs: false,
    data () {
        return {}
    }
}
</script>
<style lang="less" scoped>
.card-component {
    position: relative;
    // height: 4.2rem;
    // padding: 20px 20px 0 20px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;

    .main {
        position: relative;
        // position: absolute;
        // box-shadow: 0px 0 20px 0 #d6dadd;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 0.2rem;
        overflow: hidden;
        // background: rgba(255, 255, 255, 0.65);
        background-color: #fff;
        border-radius: 0.4rem;

        // header {
        //     display: flex;
        //     align-items: center;

        //     .left {
        //         display: flex;
        //         flex: 1;
        //         align-items: center;

        //         > i {
        //             margin-right: 0.1rem;
        //             font-size: 0.3rem;
        //             color: orange;
        //         }

        //         .title {
        //             display: flex;
        //             align-items: center;
        //             font-size: 0.24rem;
        //             font-weight: 500;
        //             line-height: 0.3rem;
        //             color: #606266;

        //             i {
        //                 margin-left: 0.03rem;
        //                 font-size: 0.18rem;
        //                 color: #c0c4cc;
        //             }
        //         }

        //         .date {
        //             margin-left: 0.12rem;
        //             line-height: 0.24rem;
        //             color: #909399;
        //         }
        //     }

        //     .right {
        //         i {
        //             font-size: 0.3rem;
        //         }
        //     }
        // }

        > section {
            flex: 1;

            canvas {
                left: -20px !important;
            }
        }

        .content {
            flex: 1;
            overflow: auto;
        }

        /deep/ .el-table {
            font-size: 0.14rem;
            color: #606266;

            thead {
                .cell {
                    font-size: 0.14rem;
                    font-weight: 500;
                    color: #a7b6c3;
                }
            }

            tr,
            th,
            td {
                // background-color: #f2f5f9;
                border: none;
            }

            .el-table__body {
                td {
                    color: #606266;
                }
            }

            /**
        * 表格内部滚动条式样
        */
            .el-table__body-wrapper {
                overflow-y: hidden;
            }

            .el-table__body-wrapper:hover {
                overflow-y: auto;
            }

            .el-table__body-wrapper::-webkit-scrollbar {
                width: 6px; // 横向滚动条
                height: 6px; // 纵向滚动条 必写
            }

            .el-table__body-wrapper::-webkit-scrollbar-thumb {
                background-color: #ddd;
                border-radius: 3px;
            }
        }

        /deep/ .el-table::before {
            height: 0 !important;
        }
    }
}
</style>
